<template>
  <header class="header--container" ref="headerLayout">
    <div class="d-flex justify-content-between align-items-center container">
      <div class="header--menu" @click="openSidebar()">
        <img src="@/assets/img/icons/menu.svg" alt="Menu" />
      </div>
      <div class="header--logo">
        <router-link to="/">
          <img :src="logoUrl" alt="Propfix Logo" />
        </router-link>
      </div>
      <client-only>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center" v-if="$mq !== 'xs'">
            <header-listing-type type="R"></header-listing-type>
            <header-listing-type type="S"></header-listing-type>
            <router-link to="/search-agent" class="mr-4-5">
              {{ $t('general.searchAgent') }}
            </router-link>
            <router-link
              to="/listing/add"
              class="mr-4-5"
              @click="!loggedIn ? showLogin() : goToAddListing('R')"
            >
              {{ $t('general.addProperty') }}
            </router-link>
          </div>
          <div class="buttons" v-if="loggedIn">
            <!--            <header-inbox></header-inbox>-->
            <!--            <header-notifications></header-notifications>-->
            <header-user></header-user>
          </div>
          <div class="btn btn-primary" @click="showLogin" v-else-if="$mq !== 'xs'">
            {{ $t('loginRegister.title') }}
          </div>
        </div>
      </client-only>
    </div>
  </header>
</template>

<script>
import HeaderListingType from '@/components/layouts/partials/header-listing-type';
// import HeaderInbox from '@/components/layouts/partials/header-inbox';
// import HeaderNotifications from '@/components/layouts/partials/header-notifications';
import HeaderUser from '@/components/layouts/partials/header-user';
import { mapState } from 'vuex';

export default {
  mixins: [],
  components: {
    HeaderListingType,
    // HeaderInbox,
    // HeaderNotifications,
    HeaderUser,
  },
  computed: {
    ...mapState({
      logoUrl: state => state.global.logoUrl,
      loggedIn: state => state.global.loggedIn,
    }),
  },
  mounted() {
    this.$store.commit('global/SET_HEADER_LAYOUT', this.$refs.headerLayout);
  },
  methods: {
    showLogin() {
      this.$modal.show('modal-login');
    },
    // eslint-disable-next-line no-unused-vars
    async goToAddListing(type) {
      // await this.$store.commit('v2/search/CLEAR_FILTER');
      // let query = await this.$store.dispatch('v2/search/setListingType', {
      //   payload: type,
      //   query: this.$route.query,
      //   clearRoute: true,
      // });
      this.$router.push({
        path: `/listing/add`,
        // query: query,
      });
    },
    openSidebar() {
      this.$store.commit('global/SET_MAIN_SIDEBAR', true);
    },
  },
};
</script>

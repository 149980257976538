<template>
  <v-menu offset-y offset-x content-class="header--popup-container">
    <template v-slot:activator="{ on, attrs }">
      <img
        src="@/assets/img/icons/user.svg"
        alt="User"
        class="single--button"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <router-link to="/profile" class="popup--item">
      {{ $t('home.sidebarMenu.profile') }}
    </router-link>
    <router-link to="/dashboard/mylisting" class="popup--item">
      {{ $t('home.sidebarMenu.mylisting') }}
    </router-link>
    <!--    <router-link to="/dashboard/mybooking" class="popup&#45;&#45;item">-->
    <!--      {{ $t('home.sidebarMenu.mybooking') }}-->
    <!--    </router-link>-->
    <router-link to="/dashboard/favorites" class="popup--item">
      {{ $t('home.sidebarMenu.fave') }}
    </router-link>
    <div class="popup--item" @click="logout">
      {{ $t('home.sidebarMenu.logout') }}
    </div>
  </v-menu>
</template>

<script>
import auth from '@/mixins/auth.js';

export default {
  name: 'header-user',
  mixins: [auth],
};
</script>
